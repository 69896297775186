import React from 'react'
import styled, { css } from 'styled-components'
import { cleanHTML } from '../utils'
import { Text } from 'rebass'

const Root = styled(Text)`
  font-size: var(--font-size-text-normal);
  line-height: var(--line-height-text-normal);

  & > *:not(:first-child) {
    margin-top: ${props => props.theme.px('space.1')};
  }

  ul {
    list-style: square;

    &:not(:first-child) {
      margin-top: ${props => props.theme.px('space.3')};
    }

    &:not(:last-child) {
      margin-bottom: ${props => props.theme.px('space.3')};
    }
  }

  li + li {
    margin-top: ${props => props.theme.px('space.1')};
  }

  /* Dropcap */
  ${props =>
    props.dropcap &&
    css`
      &:first-of-type:first-letter {
        float: left;
        font-size: var(--font-size-text-normal-dropcap);
        line-height: var(--line-height-text-normal-dropcap);
        padding-right: 0.125em;
        font-weight: 300;
      }
    `}
`

const Copytext = props => {
  const { children, as = 'div', ...rest } = props
  const cleanContent = cleanHTML(children)

  return (
    <Root
      as={as}
      dangerouslySetInnerHTML={{
        __html: cleanContent,
      }}
      {...rest}
    />
  )
}

export default Copytext
