import React from 'react'
import styled from 'styled-components'
import { margin } from 'styled-system'
import { themePx } from '../../styles/theme'
import { cleanHTML } from '../../utils'
import { FaArrowRight } from 'react-icons/fa'

const Link = styled.div`
  ${margin}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: ${themePx('space.1')} 0;
  min-height: ${themePx('space.5')};
  color: #000;
  font-size: var(--font-size-text-body);
  line-height: 100%;
  font-weight: 600;
  text-transform: none;
`

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 1;
`
const Label = styled.div`
  position: relative;
`

const Icon = styled(FaArrowRight)`
  margin-right: ${themePx('space.0')};
`

Link.defaultProps = {
}

const StyledLink = ({children, variant, className = '', ...other}) => {
  const cleanContent = cleanHTML(children)

  if (variant) {
    className += ` ${variant}`
  }

  return (
    <Link className={className} {...other}>
      <Content>
        <Icon mr={1} />
        <Label
          dangerouslySetInnerHTML={{
            __html: cleanContent,
          }}
        />
      </Content>
    </Link>
  )
}

export default StyledLink
