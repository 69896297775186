import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import styled, { css } from 'styled-components'
import { Box } from 'rebass'
import SlickSlider from "react-slick";
import Slide from "./CarouselSlide";
import alTessuti from "../../images/supplier-al_tessuti.svg"
import creationBaumann from "../../images/supplier-creation_baumann.svg"
import decortex from "../../images/supplier-decortex.svg"
import dedarMilano from "../../images/supplier-dedar_milano.png"
import jab from "../../images/supplier-jab.svg"
import kvadrat from "../../images/supplier-kvadrat.png"
import pierreFrey from "../../images/supplier-pierre_frey.png"
import romo from "../../images/supplier-romo.svg"
import rubelli from "../../images/supplier-rubelli.svg"
import sahco from "../../images/supplier-sahco.svg"
import saumViebahn from "../../images/supplier-saum_viebahn.png"

const Root = styled(Box)`
  ${({ dots }) => dots && css`
    padding-bottom: 24px;
  `}
`

class Carousel extends React.Component {
  render() {
    const settings = {
      arrows: false,
      dots: true,
      draggable: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 920,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    };
    return (
      <Root {...settings}>
        <SlickSlider {...settings}>
          <Slide src={pierreFrey} alt="Pierre Frey" />
          <Slide src={rubelli} alt="Rubelli" />
          <Slide src={creationBaumann} alt="Création Baumann" />
          <Slide src={dedarMilano} alt="Dedar Milano" />
          <Slide src={jab} alt="JAB Anstoetz" />
          <Slide src={alTessuti} alt="al Tessuti" />
          <Slide src={decortex} alt="Decortex Firenze" />
          <Slide src={kvadrat} alt="Kvadrat" />
          <Slide src={romo} alt="Romo Fabrics" />
          <Slide src={sahco} alt="SAHCO" />
          <Slide src={saumViebahn} alt="Saum und Viebahn" />
        </SlickSlider>
      </Root>
    );
  }
}

export default Carousel
