import React from 'react'
import styled from 'styled-components'
import { cleanHTML } from '../utils'
import { Text } from 'rebass'

const Root = styled(Text)`
  font-size: var(--font-size-text-large);
  line-height: var(--line-height-text-large);
`

const Lead = props => {
  const { children, as = 'div', ...rest } = props
  const cleanContent = cleanHTML(children)

  return (
    <Root
      as={as}
      dangerouslySetInnerHTML={{
        __html: cleanContent,
      }}
      {...rest}
    />
  )
}

export default Lead
