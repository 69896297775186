import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import reset from './reset'
import stickyFooter from './sticky-footer'
import { up } from '@thomaseckhardt/styled-breakpoints'

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
    easing: 'easeInOutCubic',
  })
}

const GlobalStyle = createGlobalStyle`
  :root {
    --content-max-width: 1280px;
    --content-max-width-extra: 1440px;
    --content-padding: ${props => props.theme.spacePx[2]};
    --font-size-text-small: 12px;
    --line-height-text-small: ${props => props.theme.spacePx[2]};
    --font-size-text-body: 14px;
    --line-height-text-body: ${props => props.theme.spacePx[3]};
    --font-size-text-normal: 16px;
    --line-height-text-normal: ${props => props.theme.spacePx[3]};
    --font-size-text-large: 18px;
    --line-height-text-large: ${props => props.theme.spacePx[3]};
    --font-size-headline1: 36px;
    --line-height-headline1: ${props => props.theme.spacePx[6]};
    --font-size-headline2: 28px;
    --line-height-headline2: ${props => props.theme.spacePx[4]};
    --font-size-headline3: var(--font-size-text-large);
    --line-height-headline3: var(--line-height-text-large);
    --font-size-headline4: var(--font-size-text-large);
    --line-height-headline4: var(--line-height-text-large);
    --font-size-headline5: var(--font-size-text-normal);
    --line-height-headline5: var(--line-height-text-normal);
    --font-size-headline6: var(--font-size-text-body);
    --line-height-headline6: var(--line-height-text-body);
    --font-size-text-normal-dropcap: 54px;
    --line-height-text-normal-dropcap: ${props => props.theme.spacePx[6]};

    ${up('tablet')} {      
      --content-padding: ${props => props.theme.spacePx[3]};
      --font-size-text-small: 14px;
      --font-size-text-body: 16px;
      --font-size-text-normal: 18px;
      --line-height-text-normal: ${props => props.theme.spacePx[3]};
      --font-size-text-large: 24px;
      --line-height-text-large: ${props => props.theme.spacePx[4]};
      --font-size-headline1: 62px;
      --line-height-headline1: ${props => props.theme.spacePx[9]};
      --font-size-headline2: 38px;
      --line-height-headline2: ${props => props.theme.spacePx[6]};
    }

    ${up('tabletLarge')} {
      --content-padding: ${props => props.theme.spacePx[5]};
    }
  }

  ${styledNormalize}
  ${reset}

  html {
    font-family: sans-serif;
    font-size: var(--font-size-text-body);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    font-family: ${props => props.theme.font.primary};
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  ${stickyFooter}

  /**
   * Style placeholder text
   * @see https://css-tricks.com/almanac/selectors/p/placeholder/
   */

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${props => props.theme.colors.placeholder};
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: ${props => props.theme.colors.placeholder};
    opacity: 1;
  }

  input:-moz-placeholder,
  textarea:-moz-placeholder {
    /* Firefox 18- */
    color: ${props => props.theme.colors.placeholder};
    opacity: 1;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: ${props => props.theme.colors.placeholder};
  }
`

export default GlobalStyle
