import React from 'react'
import Image from 'gatsby-image'
import styled from 'styled-components'
import { up } from '@thomaseckhardt/styled-breakpoints'
import Topline from '../components2/Topline'
import Headline from '../components2/Headline'
import ButtonGroup from '../components2/ButtonGroup'
import Button from '../components2/Button'
import Lead from '../components2/Lead'
import Copytext from '../components2/Copytext'

const Wrapper = styled.section`
  margin: ${props => props.theme.px(`space.${props.leading}`, `0`)(props)} auto
    0;
  max-width: var(--content-max-width);

  ${up('tablet')} {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 1fr;
    align-items: end;
  }
`
const ImageContainer = styled.div`
  ${up('tablet')} {
    grid-column: 1 / -3;
    grid-row-start: 1;
    padding-bottom: var(--content-padding);
  }

  ${up('tabletLarge')} {
    grid-column-start: 2;
  }
`

const Content = styled.div`
  z-index: 1;
  color: #fff;
  background-color: #000;

  ${up('tablet')} {
    grid-column: 7 / -1;
    grid-row-start: 1;
  }

  ${up('tabletLarge')} {
    grid-column: 8 / -2;
  }
`

const Text = styled.div`
  margin: 0 auto;
  padding: var(--content-padding);
`

const Heading = styled.div`
  max-width: 520px;
`

const StyledTopline = styled(Topline)`
  margin-bottom: 4px;
`

const Actions = styled.div`
  margin-top: ${props => props.theme.spacePx[2]};
`

const Teaser = props => {
  const hasHeadline = 'headline' in props
  const hasLead = 'lead' in props
  const hasCopytext = 'copytext' in props

  return (
    <Wrapper {...props}>
      <ImageContainer>
        <Image {...props.image} />
      </ImageContainer>
      <Content>
        <Text>
          {hasHeadline && (
            <Heading>
              {props.topline && (
                <StyledTopline tag="h1">{props.topline}</StyledTopline>
              )}
              {props.headline && (
                <Headline headlineSize="2" tag="h2">
                  {props.headline}
                </Headline>
              )}
            </Heading>
          )}
          {hasLead && <Lead forwardedAs="p">{props.lead}</Lead>}
          {hasCopytext && (
            <Copytext dropcap={props.dropcap}>{props.copytext}</Copytext>
          )}
          <Actions>
            <ButtonGroup>
              <Button variant="secondary" {...props.action}>
                {props.action?.label || `Mehr erfahren`}
              </Button>
            </ButtonGroup>
          </Actions>
        </Text>
      </Content>
    </Wrapper>
  )
}

Teaser.defaultProps = {
  leading: 9,
}

export default Teaser
