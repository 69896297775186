import React from 'react'
import styled from 'styled-components'
import { cleanHTML } from '../utils'
import { Text } from 'rebass'

const Root = styled(Text)`
  font-size: var(${props => `--font-size-headline${props.headlineSize}`});
  line-height: var(${props => `--line-height-headline${props.headlineSize}`});
  font-weight: 700;
`

const Headline = props => {
  const { children, ...rest } = props
  const cleanContent = cleanHTML(children)

  return (
    <Root
      dangerouslySetInnerHTML={{
        __html: cleanContent,
      }}
      {...rest}
    />
  )
}

Headline.defaultProps = {
  headlineSize: '2',
}

export default Headline
