import React from 'react'
import styled, { css } from 'styled-components'

const spacing = 16
const spacingBar = -1

const StyledButtonGroup = styled.div`
  display: inline-flex;
  flex-flow: row wrap;
  margin: ${spacing * -1}px 0 0 ${spacing * -1}px;

  /* We use the wildcard selector to be independent
     of the type of descendent elements. */
  > * {
    display: flex;
    flex: 1 1 auto;
    margin: ${spacing}px 0 0 ${spacing}px;
  }

  ${props =>
    (props.bar || props.stacked) &&
    css`
      margin: ${spacingBar * -1}px 0 0 ${spacingBar * -1}px;

      > * {
        margin: ${spacingBar}px 0 0 ${spacingBar}px;
      }
    `}

  ${props =>
    props.bar &&
    css`
      > *:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      > *:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    `}

  ${props =>
    props.stacked &&
    css`
      flex-flow: column nowrap;

      > *:not(:last-child) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      > *:not(:first-child) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    `}
`

const ButtonGroup = props => {
  const { children, ...rest } = props

  return <StyledButtonGroup {...rest}>{children}</StyledButtonGroup>
}

export default ButtonGroup
