import React from 'react'
import styled from 'styled-components'
import { margin } from 'styled-system'
import { themePx } from '../../styles/theme'
import { cleanHTML } from '../../utils'

// import { Button as RebassButton } from 'rebass'
// const Button = styled(RebassButton)`
//   cursor: pointer;
// `

const Button = styled.div`
  ${margin}
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${themePx('space.1')} ${themePx('space.2')};
  min-height: ${themePx('space.5')};
  color: #000;
  font-size: var(--font-size-text-body);
  line-height: 100%;
  font-weight: 600;
  letter-spacing: 0.0625em;
  text-transform: ${props => {
    return props.isUppercase ? 'uppercase' : 'none'
  }};

  &.primary {
    color: #fff;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;
`
const Label = styled.div`
  position: relative;
`

const Fill = styled.span`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  transition: transform 0.2s ease-out;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    transition: transform 0.2s ease-out;
  }
`

const PrimaryFill = styled(Fill)`
  &::after {
    background-color: #000;
  }

  ${/* sc-selector */ Button}:hover & {
    transform: skew(-2deg, -0.5deg) scale(1.025);
  }
`

const SecondaryFill = styled(Fill)`
  &::after {
    background-color: #fff;
  }

  ${/* sc-selector */ Button}:hover & {
    transform: scale(1.025);
  }

  ${/* sc-selector */ Button}:hover &::after {
    transform: skew(-2deg, -0.5deg);
  }
`

const StyledButton = ({ children, variant, className = '', ...other }) => {
  const cleanContent = cleanHTML(children)

  if (variant) {
    className += ` ${variant}`
  }

  return (
    <Button className={className} {...other}>
      <Content>
        <Label
          dangerouslySetInnerHTML={{
            __html: cleanContent,
          }}
        />
        {/* <Icon></Icon> */}
      </Content>
      {variant === 'primary' && <PrimaryFill aria-hidden="true" />}
      {variant === 'secondary' && <SecondaryFill aria-hidden="true" />}
    </Button>
  )
}

StyledButton.defaultProps = {
  isUppercase: true,
}

export default StyledButton
