import React from 'react'
import StyledLinkComponent from './styled'
import { Link as GatsbyLink } from 'gatsby'

const internalLinkElem = ({ children, to, as, activeClassName, ...rest }) => {
  return (
    <StyledLinkComponent
      as={GatsbyLink}
      to={to}
      activeClassName={activeClassName}
      {...rest}
    >
      {children}
    </StyledLinkComponent>
  )
}

const externalLinkElem = ({ children, to, as, target, ...rest }) => {
  if (!target) {
    target = '_blank'
  }

  return (
    <StyledLinkComponent
      as={as}
      href={to}
      target={target}
      rel='nofollow'
      {...rest}
    >
      {children}
    </StyledLinkComponent>
  )
}

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = props => {

  // We assume that any external link begins with 'http://' or 'https://'
  const external = /^https?:\/\/(.*)/.test(props.to)

  return external ? externalLinkElem(props) : internalLinkElem(props)
}

Link.defaultProps = {
  as: `a`,
  activeClassName: `active`
}

export default Link
