import DOMPurify from 'dompurify'

export const sanitize = DOMPurify.sanitize

export const cleanHTML = function(dangerousHTML) {
  const sanitized = sanitize ? sanitize(dangerousHTML) : dangerousHTML
  return sanitized || ''
}

export const nl2br = function(text = '') {
  return typeof text === 'string' ? text.replace(/\n/g, '<br />') : ''
}
