import { themeGet } from '@styled-system/theme-get'

// Utility function to get pixel values from a unitless scale in our custom theme
const themePx = (...args) => props => {
  return `${themeGet(...args)(props)}px`
}

const themePxNeg = (...args) => props => {
  return `${themeGet(...args)(props) * -1}px`
}

const breakpoints = {
  phone: '480px',
  phoneLarge: '600px',
  tablet: '768px',
  tabletLarge: '1024px',
  laptop: '1280px',
  laptopLarge: '1440px',
  desktop: '1680px',
  desktopLarge: '1920px',
  cinema: '2560px',
  cinemaLarge: '2880px'
}

const space = [4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128, 256, 512, 1024, 1280]
const spacePx = space.map(val => `${val}px`)

const theme = {
  colors: {
    base: '#000000', // Black
    secondary: '#e9e9e9', // Medium Gray
    tertiary: '#f3f3f3', // Light Gray
    highlight: '#5b8bf7', // Light Blue
    // Ziegler
    placeholder: '#ccc',
    light: `#d5d5d5`

  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },


  name: 'Ziegler',
  font: {
    primary: 'futura-pt'
  },
  breakpoints,
  space,
  spacePx,
  px: themePx,
  header: {
    height: {
      phone: 60
    }
  }
  // spacePx: (multiplier, adder = 0) => `${multiplier * 8 + adder}px`
}

export { themeGet, themePxNeg, themePx }
export default theme
