import React from 'react'
import styled, { css } from 'styled-components'
import { up, down } from '@thomaseckhardt/styled-breakpoints'
import PageTeaser from './PageTeaser'

export const VARIANT_OPENER = 'opener'

const Root = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: var(--content-max-width);
  ${props =>
    props.leading > 0 &&
    css`
      padding-top: ${props =>
        props.theme.px(`space.${props.leading}`, `0`)(props)};
    `}
`

const Content = styled.div`
  padding: 0 var(--content-padding);

  ${down('tablet')} {

    > *:not(:first-child) {
      margin-top: ${props => props.theme.px('space.7')};
    }
  }

  ${up('tablet')} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: ${props => props.theme.px('space.6')};
    grid-row-gap: ${props => props.theme.px('space.7')};
  }
`

const PageTeaserGrid = props => {
  const { items, ...rest } = props

  const itemElems = []
  items.forEach((item, index) => {
    itemElems.push(<PageTeaser key={index} {...item} />)
  })

  return (
    <Root {...rest}>
      <Content>
        {itemElems}
      </Content>
    </Root>
  )
}

PageTeaserGrid.defaultProps = {
  leading: 9
}

export default PageTeaserGrid
