import React from 'react'
import styled from 'styled-components'
import { up } from '@thomaseckhardt/styled-breakpoints'
import Topline from '../../components2/Topline'
import Headline from '../../components2/Headline.js'
import Subline from '../../components2/Subline.js'
import Copytext from '../../components2/Copytext.js'
import Link from '../../components2/Link'
import { Box } from 'rebass'

export const VARIANT_OPENER = 'opener'
export const VARIANT_TEXT_INTENDED = 'text-intended'
export const VARIANT_TEXT_CENTERED = 'text-centered'
export const VARIANT_IMAGE_TEXT = 'image-text'
export const ARTICLE_VARIANT_TRIO = 'trio'
export const VARIANT_TEXT_ALIGN_CENTERED_NARROW = 'text-align-centered-narrow'

const Root = styled.div`
  ${up('tablet')} {
    display: flex;
    flex-flow: row wrap;
  }
`

const Text = styled.div`
  position: relative;
  flex: 1 1 100%;
`

const Heading = styled.div`
  position: relative;
`

const CtaContainer = styled(Box)`
  position: relative;
  align-self: flex-end;
  flex: 0 0 auto;
`

const PageTeaser = props => {
  const { topline, headline, subline, copytext, cta, ...rest } = props
  const hasText = headline || copytext

  return (
    <Root {...rest}>
      {hasText && (
        <Text>
          {headline && (
            <Heading>
              {topline && <Topline>{topline}</Topline>}
              {headline && <Headline headlineSize={2}>{headline}</Headline>}
              {subline && <Subline>{subline}</Subline>}
            </Heading>
          )}
          {copytext && <Copytext mt={1}>{copytext}</Copytext>}
        </Text>
      )}
      {cta && (
        <CtaContainer mt={0}>
          <Link {...cta}>{cta.label}</Link>
        </CtaContainer>
      )}
    </Root>
  )
}

PageTeaser.defaultProps = {}

export default PageTeaser
