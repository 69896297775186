import React from 'react'
import GatsbyImage from 'gatsby-image'
import styled from 'styled-components'
import { up } from '@thomaseckhardt/styled-breakpoints'
import { Box } from 'rebass'
import Topline from '../components2/Topline'
import Headline from '../components2/Headline'

const Wrapper = styled.section`
  position: relative;
  margin: 0 auto;

  ${up('desktop')} {
    max-width: var(--content-max-width-extra);
  }
`

const ImageContainer = styled(Box)`
  position: relative;
`

const Image = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 320px;

  ${up('tablet')} {
    height: 480px;
  }

  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2) 30%,
      rgba(0, 0, 0, 0)
    );
  }
`

const Caption = styled(Box)`
  position: absolute;
  left: calc(100% - 20px);
  top: calc(100% - 12px);
  color: white;
  font-size: 12px;
  transform: rotate(270deg);
  transform-origin: 0 0;
  white-space: nowrap;
  font-weight: 600;
`

const Content = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Text = styled.div`
  flex: 1 1 auto;
  margin: 0 auto;
  padding: var(--content-padding);
  max-width: var(--content-max-width);
  color: white;
`

const Heading = styled.div`
  position: relative;
  max-width: 640px;
`

const Welcome = props => {
  const hasHeading = props.headline && props.topline

  return (
    <Wrapper>
      <ImageContainer as="figure">
        <Image
          height={props.height}
          fluid={props.image.fluid}
          backgroundColor={'#f5f5f5'}
        />
        <Caption as="figcaption">
          <span>© ZIMMER + RHODE</span>
        </Caption>
      </ImageContainer>
      <Content>
        <Text>
          {hasHeading && (
            <Heading as="h1">
              {props.topline && (
                <Topline tag="div" mb={0}>
                  {props.topline}
                </Topline>
              )}
              {props.headline && (
                <Headline headlineSize="1" tag="div">
                  {props.headline}
                </Headline>
              )}
            </Heading>
          )}
        </Text>
      </Content>
    </Wrapper>
  )
}

export default Welcome
