import React from 'react'
import { graphql } from 'gatsby'
import Welcome from '../modules/Welcome'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'
import PageTeaserGrid from '../modules/PageTeaserGrid'
import Numbers from '../modules/Numbers'
import Brands from '../modules/Brands'
import Teaser from '../modules/Teaser'
import HeroTeaser from '../modules/HeroTeaser'

const pageConfig = {
  title: `${config.siteTitle}`,
  slug: `/`,
  path: `/`,
}

const Page = ({ data }) => {
  const postNode = {
    title: pageConfig.title,
  }

  const welcome = {
    topline: `In Ludwigsburg und Gundelsheim`,
    headline: `Werkstätten für Raumaus­stattung`,
    image: {
      alt: 'Placeholder',
      fluid: data.welcome.childImageSharp.fluid,
    },
    height: 480,
  }

  const pageTeaserGrid = {
    items: [
      {
        headline: `Polstern`,
        copytext: `Bei uns ist das Aufpolstern und Neubeziehen von Möbelstücken allgegenwärtig und im täglichen Geschäft fest verankert.`,
        cta: {
          label: 'Mehr erfahren',
          to: '/polstern',
        },
      },
      {
        headline: `Farben`,
        copytext: `Mit unserem modernen Mischsystem stellen wir Ihre persönliche Wunschfarbe her, die wir Ihnen auch Jahre später wieder mischen können.`,
        cta: {
          label: 'Mehr erfahren',
          to: '/wandgestaltung/#farben',
        },
      },
      {
        headline: `Gardinen`,
        copytext: `Wir verwirklichen Ihre Wünsche für schöne Dekorationen und fertigen in unserem hauseigenen Nähatelier die Gardinen an.`,
        cta: {
          label: 'Mehr erfahren',
          to: '/sicht-sonnenschutz',
        },
      },
    ],
  }

  const brands = {
    headline: `Unsere Marken`,
    subline: `Eine Auswahl namhafter Hersteller, die wir in unserem Sortiment führen.`,
  }

  const floorTeaser = {
    topline: `Unser Kundenservice`,
    headline: `Von Boden verlegen bis Verleih von Reinigungsgeräten.`,
    action: {
      to: `/kundenservice#boden`,
    },
    image: {
      objectPosition: `100% 100%`,
      fluid: data.floorTeaser.childImageSharp.fluid,
    },
  }

  const fassdichteTeaser = {
    topline: `Fassdichte`,
    headline: `Dieses Edelwachs produzieren wir bereits seit mehr als 70 Jahren`,
    image: {
      alt: 'Placeholder',
      fluid: data.fassdichte.childImageSharp.fluid,
    },
    actions: [
      {
        variant: 'secondary',
        to: `/fassdichte`,
        label: `Mehr erfahren`,
      },
    ],
    height: 480,
  }

  const jobsTeaser = {
    leading: 10,
    topline: `Wir suchen dich`,
    headline: `Raumausstatter/-in`,
    action: {
      label: `Jetzt bewerben`,
      to: `/stellenangebote`,
    },
    image: {
      objectPosition: `100% 100%`,
      fluid: data.serviceTeaser.childImageSharp.fluid,
    },
  }

  return (
    <Layout>
      <Helmet>
        <title>{pageConfig.title}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath={pageConfig.path} customTitle />

      <div>
        <Welcome {...welcome} />
        <Numbers />
        <PageTeaserGrid {...pageTeaserGrid} />
        <Teaser {...floorTeaser} />
        <Brands {...brands} />
        <HeroTeaser {...fassdichteTeaser} />
        <Teaser as="aside" {...jobsTeaser} />
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    welcome: file(relativePath: { eq: "welcome-flip.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    floorTeaser: file(relativePath: { eq: "service-boden-22.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fassdichte: file(relativePath: { eq: "service-fassdichte.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    serviceTeaser: file(relativePath: { eq: "service-gardinen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
