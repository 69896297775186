import { css } from 'styled-components';
import { down } from '@thomaseckhardt/styled-breakpoints'

export const stickyFooter = css`
  html, body {
    height: 100%;
  }
  
  #___gatsby,
  #___gatsby > *:first-child {
    min-height: 100%;
  } 

  body,
  #___gatsby,
  #___gatsby > *:first-child {
    display: flex;
    flex-direction: column;
  }
  
  #___gatsby > *:first-child {
    ${down('tablet')} {
      padding-top: ${props => props.theme.px('header.height.phone')};
    }
  }
`

export default stickyFooter;
