import React from 'react'
import styled from 'styled-components'
import { cleanHTML, nl2br } from '../utils'

import { Box, Text } from 'rebass'

const Root = styled(Box)``

const Infos = styled.div`
  display: flex;
  font-size: var(--font-size-text-normal);
  text-align: center;
  line-height: ${props => props.theme.spacePx[3]};
`

const InfoBox = styled.div`
  flex: 0 0 auto;
  width: 50%;
`

const InfoTitle = styled(Text)`
  font-size: var(--font-size-text-small);
  color: #666666;
  text-transform: uppercase;
`

const MapContainer = styled(Box)`
  display: block;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 1px solid #d5d5d5;
`

const Map = styled.img`
  display: block;
  width: 100%;
  border-radius: 50%;
`

const LocationDetail = ({
  phone,
  address,
  titleWeekday,
  titleWeekend,
  openingHoursWeekday,
  openingHoursWeekend,
  map,
  mapLink,
  ...rest
}) => {
  const phoneClean = cleanHTML(nl2br(phone))
  const addressClean = cleanHTML(nl2br(address))
  const titleWeekdayClean = cleanHTML(nl2br(titleWeekday))
  const titleWeekendClean = cleanHTML(nl2br(titleWeekend))
  const openingHoursWeekdayClean = cleanHTML(nl2br(openingHoursWeekday))
  const openingHoursWeekendClean = cleanHTML(nl2br(openingHoursWeekend))

  return (
    <Root {...rest}>
      <MapContainer
        as="a"
        href={mapLink}
        target="_blank"
        rel="nofollow"
        mx="auto"
      >
        <Map src={map.image.src} />
      </MapContainer>
      <Infos>
        <InfoBox>
          <Box>
            <InfoTitle as="h6">Telefon</InfoTitle>
            <Text
              fontSize="var(--font-size-text-large)"
              letterSpacing="0.04em"
              dangerouslySetInnerHTML={{
                __html: phoneClean,
              }}
            />
          </Box>
          <Box mt="1">
            {/* <InfoTitle as="h6">Addresse</InfoTitle> */}
            <Text
              dangerouslySetInnerHTML={{
                __html: addressClean,
              }}
            />
          </Box>
        </InfoBox>
        <InfoBox>
          <Box>
            <InfoTitle as="h6">{titleWeekdayClean}</InfoTitle>
            <Text
              dangerouslySetInnerHTML={{
                __html: openingHoursWeekdayClean,
              }}
            />
          </Box>
          <Box mt="1">
            <InfoTitle as="h6">{titleWeekendClean}</InfoTitle>
            <Text
              dangerouslySetInnerHTML={{
                __html: openingHoursWeekendClean,
              }}
            />
          </Box>
        </InfoBox>
      </Infos>
    </Root>
  )
}

export default LocationDetail
