import { css } from 'styled-components'

export const reset = css`
  /**
   * A very simple reset that sits on top of Normalize.css
   */
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  p,
  pre,
  dl,
  dd,
  ol,
  ul,
  figure,
  hr,
  fieldset,
  legend {
    padding: 0;
    margin: 0;
    font-weight: inherit;
  }

  ol,
  ul {
    list-style: none;
  }

  /**
   * Remove trailing margins from nested lists
   */
  li > {
    ol,
    ul {
      margin-bottom: 0;
    }
  }

  /**
   * Remove default table spacing
   */
  table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  /**
   * Reset Chrome and Firefox behaviour which sets a "min-width: min-content;"
   * on fieldsets.
   */
  fieldset {
    min-width: 0;
    border: 0;
  }

  /**
   * Remove address styling
   */
  address {
    font-style: normal;
  }

  /**
   * Reset link and button styles
   */

  a,
  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    text-align: inherit;
    outline: none;
    line-height: inherit;
    appearance: none;
  }

  /**
   * [1] Remove focus outline
   * [2] Remove the tap highlight color on touch devices
   */
  a,
  button,
  input,
  label,
  select,
  textarea {
    /* [2] */

    /* outline: none; */

    /* [3] */
    /* stylelint-disable */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
    /* stylelint-enable */
  }

  /**
   * [1] Remove × icon on IE; https://msdn.microsoft.com/library/Hh771818
   * [2] Remove reveal button on IE; https://msdn.microsoft.com/de-de/library/hh771833
   * [3] Remove inner focus on Firefox; https://github.com/necolas/normalize.css/issues/393
   */

  ::-ms-clear {
    display: none; /* [1] */
  }

  ::-ms-reveal {
    display: none; /* [2] */
  }

  ::-moz-focus-inner {
    padding: 0; /* [3] */
    border: 0; /* [3] */
  }

  select::-ms-expand {
    display: none;
  }
`

export default reset
