import React from "react";
import styled from 'styled-components'
import { themeGet, themePx } from '../../styles/theme'
import { Box } from 'rebass'

// const contentPadding = themePx('space.3');
const contentPadding = `8px`;

const Root = styled(Box)`
  position: relative;
  padding: ${themePx('space.1')};
`

const Content = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${contentPadding};
  height: ${themePx('space.16')};
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
  flex: 0 0 auto;
`

const Background = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid ${themeGet('colors.light')};
  background-color: #fff;
  z-index: -1;
`

class Slide extends React.Component {
  render() {
    const { src, alt, ...props } = this.props;
    return (
      <Root {...props}>
        <Content>
          <Background />
          <Image src={src} alt={alt} />
        </Content>
      </Root>
    );
  }
}

export default Slide
