import React from 'react'
import styled, { css } from 'styled-components'
import { themePx, themePxNeg } from '../../styles/theme'
import { Box } from 'rebass'
import svgFamilyTradition from '../../images/hundert-jahre-familientradition.png'
import svgGenerations from '../../images/vierte-generation-geschaeftsfuerhung.png'
import svgLocations from '../../images/zwei-standorte.png'

const Root = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: var(--content-max-width);
  ${props =>
    props.leading > 0 &&
    css`
      padding-top: ${props =>
        props.theme.px(`space.${props.leading}`, `0`)(props)};
    `}
`

const Content = styled.div`
  padding: 0 var(--content-padding);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: ${themePxNeg('space.4')} 0 0 -5.8%;
`

const ImageContainer = styled(Box)`
  position: relative;
  flex: 1 1 0;
  width: 33%;
  min-width: 208px;
  max-width: 288px;
  margin: ${themePx('space.4')} 0 0 5.8%;
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

const PageTeaserGrid = props => {
  return (
    <Root {...props}>
      <Content>
        <ImageContainer>
          <Image src={svgFamilyTradition} alt="Logo" />
        </ImageContainer>
        <ImageContainer>
          <Image src={svgGenerations} alt="Logo" />
        </ImageContainer>
        <ImageContainer>
          <Image src={svgLocations} alt="Logo" />
        </ImageContainer>
      </Content>
    </Root>
  )
}

PageTeaserGrid.defaultProps = {
  leading: 9
}

export default PageTeaserGrid
