import React from 'react'
import styled, { css } from 'styled-components'
import { Box } from 'rebass'
import { themePx } from '../../styles/theme'
import Topline from '../../components2/Topline'
import Headline from '../../components2/Headline'
import Subline from '../../components2/Subline'
import Lead from '../../components2/Lead'
import Copytext from '../../components2/Copytext'
import Carousel from './Carousel'

const Root = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: var(--content-max-width);
  ${props =>
    props.leading > 0 &&
    css`
      padding-top: ${props =>
        props.theme.px(`space.${props.leading}`, `0`)(props)};
    `}
`

const Content = styled.div`
  padding: 0 var(--content-padding);
`

const Text = styled.div`
  margin: 0 auto;
  max-width: 576px;
  text-align: center;
  margin-bottom: ${themePx('space.4')};
`

const Heading = styled(Box)`
  position: relative;
`

const PageTeaserGrid = props => {
  const { topline, headline, subline, copytext, lead, ...rest } = props
  const hasText = headline || copytext || lead
  return (
    <Root {...rest}>
      <Content>
        {hasText && (
          <Text>
            {headline && (
              <Heading>
                {topline && <Topline>{topline}</Topline>}
                {headline && <Headline headlineSize={2}>{headline}</Headline>}
                {subline && <Subline>{subline}</Subline>}
              </Heading>
            )}
            {lead && <Lead forwardedAs="p">{lead}</Lead>}
            {copytext && <Copytext>{copytext}</Copytext>}
          </Text>
        )}
        <Carousel></Carousel>
      </Content>
    </Root>
  )
}

PageTeaserGrid.defaultProps = {
  leading: 11,
}

export default PageTeaserGrid
