import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import { siteTitle } from '../utils/siteConfig'
import Header from '../modules/Header'
import PageFooter from '../modules/PageFooter'
import Locations from '../modules/Locations'

const Main = styled.main`
  display: block;

  /* Sticky Footer */
  flex: 1 0 auto;
`

// const PromotionBar = styled.aside`
//   background-color: #d2bcbc;

//   p {
//     width: 100%;
//     max-width: 1280px;
//     margin: 0 auto;
//     padding: 20px var(--content-padding);
//     font-weight: bold;
//   }
// `

const Template = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>{siteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" href="https://use.typekit.net/lic6oky.css" />
      </Helmet>

      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <Header />
          <Main>
            {children}
            <Locations />
          </Main>
          <PageFooter />
        </>
      </ThemeProvider>
    </>
  )
}

export default Template
