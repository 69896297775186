import React from 'react'
import Image from 'gatsby-image'
import styled, { css } from 'styled-components'
import { up } from '@thomaseckhardt/styled-breakpoints'
import Topline from '../components2/Topline'
import Headline from '../components2/Headline'
import ButtonGroup from '../components2/ButtonGroup'
import Button from '../components2/Button'

const Wrapper = styled.section`
  position: relative;
  margin: 0 auto;

  ${up('desktop')} {
    max-width: var(--content-max-width-extra);
  }

  ${props =>
    props.leading > 0 &&
    css`
      padding-top: ${props =>
        props.theme.px(`space.${props.leading}`, `0`)(props)};
    `}
`
const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 320px;

  ${up('tablet')} {
    height: 480px;
  }

  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6) 30%,
      rgba(0, 0, 0, 0)
    );
  }
`

const Content = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Text = styled.div`
  flex: 1 1 auto;
  margin: 0 auto;
  max-width: var(--content-max-width);
  padding: var(--content-padding);
  color: white;
`

const Heading = styled.div`
  max-width: 640px;
`

const StyledTopline = styled(Topline)`
  margin-bottom: 4px;
`

const Actions = styled.div`
  margin-top: ${props => props.theme.spacePx[2]};
`

const HeroTeaser = props => {
  const hasHeading = props.headline && props.topline
  const hasActions = props.actions && props.actions.length

  const actionElems = []
  if (hasActions) {
    props.actions.forEach((item, index) => {
      const { label, ...rest } = item
      actionElems.push(
        <Button key={index} {...rest}>
          {label}
        </Button>
      )
    })
  }

  return (
    <Wrapper {...props}>
      <StyledImage
        height={props.height}
        fluid={props.image.fluid}
        backgroundColor={'#f5f5f5'}
      />
      <Content>
        <Text>
          {hasHeading && (
            <Heading>
              {props.topline && (
                <StyledTopline tag="h1">{props.topline}</StyledTopline>
              )}
              {props.headline && (
                <Headline headlineSize="2" tag="h2">
                  {props.headline}
                </Headline>
              )}
            </Heading>
          )}
          {hasActions && (
            <Actions>
              <ButtonGroup>{actionElems}</ButtonGroup>
            </Actions>
          )}
        </Text>
      </Content>
    </Wrapper>
  )
}

HeroTeaser.defaultProps = {
  leading: 9,
}

export default HeroTeaser
