import React from 'react'
// import styled from 'styled-components'
import StyledButtonComponent from './styled'
import { Link as GatsbyLink } from 'gatsby'

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Button = ({
  children,
  to,
  as,
  activeClassName,
  target,
  rel,
  isUppercase,
  ...other
}) => {
  // We assume that any internal link (intended for Gatsby) will start with
  // exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)

  if (!internal) {
    if (rel === undefined) {
      rel = `nofollow`
    }
  }

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <StyledButtonComponent
        as={GatsbyLink}
        to={to}
        activeClassName={activeClassName}
        rel={rel}
        isUppercase={isUppercase}
        {...other}
      >
        {children}
      </StyledButtonComponent>
    )
  }
  return (
    <StyledButtonComponent
      as={as}
      href={to}
      target={target}
      rel={rel}
      isUppercase={isUppercase}
      {...other}
    >
      {children}
    </StyledButtonComponent>
  )
}

Button.defaultProps = {
  as: `a`,
  activeClassName: `active`,
  target: `_blank`,
  isUppercase: true,
}

export default Button
