import React from 'react'
import styled from 'styled-components'
import { cleanHTML } from '../utils'

const Root = styled.div`
  font-size: var(--font-size-headline${props => props.size});
  line-height: var(--line-height-headline${props => props.size});
`

const Subline = props => {
  const { children, ...rest } = props
  const cleanContent = cleanHTML(children)

  return (
    <Root
      dangerouslySetInnerHTML={{
        __html: cleanContent,
      }}
      {...rest}
    />
  )
}

Subline.defaultProps = {
  size: '4',
}

export default Subline
