const currentYear = new Date().getFullYear()

module.exports = {
  siteTitle: 'Ziegler Raumausstattung',
  siteTitleAlt: 'Ziegler Raumausstattung', // This allows an alternative site title for SEO schema.
  publisher: 'Ziegler GmbH', // Organization name used for SEO schema
  siteDescription: 'Werkstätten für Raumausstattung in Ludwigsburg und Gundelsheim',
  siteUrl: 'https://ziegler.netlify.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: 'Ziegler GmbH', // Author for RSS author segment and SEO schema
  authorUrl: 'https://www.raumausstattung-ziegler.de/ueber-uns/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@twitter', // Change for Twitter Cards
  shortTitle: 'Ziegler', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/favicon.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#fff', // Used for Offline Manifest
  themeColor: '#fff', // Used for Offline Manifest
  copyright: `Copyright © ${currentYear} Ziegler GmbH`, // Copyright string for the RSS feed,
  mapboxStaticUrl: 'https://api.mapbox.com/styles/v1/eckecke/cjw10wziu1vi81co3ralq1qsj/static/',
  mapboxToken: 'pk.eyJ1IjoiZWNrZWNrZSIsImEiOiJjazE5bWhjODgxeTI3M29xamRucWZmZWlwIn0.HprVyUZdxBqL2ib7iAu-xg',
  googleMapsLinkLudwigsburg: 'https://goo.gl/maps/Rn7vtLw9TA9X2W1S7',
  googleMapsLinkGundelsheim: 'https://goo.gl/maps/hfzCXUB5MtwHxSuX6',
  googlePlacesApiKey: 'AIzaSyCcRrDU9jbY80C5Ev2_ZAktKhQdhnp8OJw',
  locationGundelsheim: {
    googlePlace: {
      id: 'ChIJ4dneTokgmEcR75LyHZ2N2nY'
    }
  },
  // https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJ4dneTokgmEcR75LyHZ2N2nY&fields=name,opening_hours&key=AIzaSyCcRrDU9jbY80C5Ev2_ZAktKhQdhnp8OJw
}
