import React from 'react'
import styled from 'styled-components'
import { cleanHTML } from '../utils'
import { Text } from 'rebass'

const Root = styled(Text)`
  font-size: var(--font-size-headline6);
  line-height: var(--font-size-headline6);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.0625em;
`

const Topline = props => {
  const { children, ...rest } = props
  const cleanContent = cleanHTML(children)

  return (
    <Root
      dangerouslySetInnerHTML={{
        __html: cleanContent,
      }}
      {...rest}
    />
  )
}

Topline.defaultProps = {}

export default Topline
