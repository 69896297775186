import React from 'react'
import styled, { css } from 'styled-components'
import { up } from '@thomaseckhardt/styled-breakpoints'
import Headline from '../components2/Headline.js'
import Lead from '../components2/Lead.js'
import Copytext from '../components2/Copytext.js'
import LocationDetail from '../components2/LocationDetail.js'
import { Box } from 'rebass'
import {
  mapboxStaticUrl,
  mapboxToken,
  googleMapsLinkLudwigsburg,
  googleMapsLinkGundelsheim,
} from '../utils/siteConfig'

export const VARIANT_OPENER = 'opener'
export const VARIANT_TEXT_INTENDED = 'text-intended'
export const VARIANT_TEXT_CENTERED = 'text-centered'
export const VARIANT_IMAGE_TEXT = 'image-text'
export const ARTICLE_VARIANT_TRIO = 'trio'

const Root = styled(Box)`
  position: relative;
  margin: 0 auto;
  max-width: var(--content-max-width);
  ${props =>
    props.leading > 0 &&
    css`
      padding-top: ${props =>
        props.theme.px(`space.${props.leading}`, `0`)(props)};
    `}
`

const Content = styled.div`
  position: relative;
`

const Text = styled(Box)`
  max-width: 640px;
  padding-left: ${(1 / 16) * 100}%;
  padding-right: ${(1 / 16) * 100}%;
  margin-left: auto;
  margin-right: auto;
`

const LocationsContainer = styled(Box)`
  position: relative;

  ${up('tablet')} {
    display: flex;

    > * {
      flex: 0 0 50%;
    }
  }
`

const Line = styled.div`
  position: absolute;
  top: 50%;
  right: ${(1 / 16) * 100}%;
  left: ${(1 / 16) * 100}%;
  bottom: auto;
  width: auto;
  height: 1px;
  border-bottom: 1px dashed #979797;

  ${up('tablet')} {
    top: -16px;
    right: auto;
    bottom: -16px;
    left: 50%;
    width: 1px;
    height: auto;
    border-bottom: none;
    border-left: 1px dashed #979797;
  }
`

const Locations = props => {
  const hasHeadline = 'headline' in props
  const hasLead = 'lead' in props
  const hasCopytext = 'copytext' in props
  const hasText = hasHeadline || hasLead || hasCopytext

  const locationElems = props.locations.map((location, index) => (
    <LocationDetail
      key={index}
      mt={index > 0 ? { _: 4, tablet: 0 } : null}
      {...location}
    />
  ))

  return (
    <Root {...props}>
      <Content>
        {hasText && (
          <Text>
            {hasHeadline && (
              <Headline textAlign="center">{props.headline}</Headline>
            )}
            {hasLead && (
              <Lead forwardedAs="p" textAlign="center">
                {props.lead}
              </Lead>
            )}
            {hasCopytext && (
              <Copytext dropcap={props.dropcap} textAlign="center" mt={1}>
                {props.copytext}
              </Copytext>
            )}
          </Text>
        )}
        <LocationsContainer
          mt={{ _: 4, tablet: 5 }}
          px={{
            tabletLarge: `${(1 / 16) * 100}%`,
            laptop: `${(2 / 16) * 100}%`,
          }}
        >
          {locationElems}
          <Line />
        </LocationsContainer>
      </Content>
    </Root>
  )
}

Locations.defaultProps = {
  leading: 16,
  headline: 'Unsere Standorte',
  copytext: `Wir freuen uns auf Ihren Besuch in Ludwigsburg und Gundelsheim.<br/>Sie können uns auch gerne an <br/><br/><a href="mailto:info@raumausstattung-ziegler.de"><strong>info@raumausstattung-ziegler.de</strong></a><br/></br> schreiben, wir melden uns dann schnellstmöglich bei Ihnen zurück.`,
  locations: [
    {
      phone: '06269 45095',
      address: 'Eisenbahnstraße 17\n74831 Gundelsheim',
      titleWeekday: 'Montag—Freitag',
      titleWeekend: 'Samstag',
      openingHoursWeekday: '9—12 Uhr & 14—17:30 Uhr',
      openingHoursWeekend: '9—12 Uhr',
      map: {
        image: {
          src: `/images/map-gundelsheim.png`,
        },
      },
      mapLink: googleMapsLinkGundelsheim,
    },
    {
      phone: '07141 923779',
      address: 'Eberhardstraße 12\n71634 Ludwigsburg',
      titleWeekday: 'Montag—Freitag Vormittag',
      titleWeekend: 'Freitag Nachmittag & Samstag',
      openingHoursWeekday: '9:30—18 Uhr',
      openingHoursWeekend: 'Termin nach Vereinbarung',
      map: {
        image: {
          src: `/images/map-ludwigsburg.png`,
        },
      },
      mapLink: googleMapsLinkLudwigsburg,
    },
  ],
}

export default Locations
